.disabled-signup-button {
  background-color:rgba(79, 209, 196, 0.438);
}

.disabled-signup-button:hover {
  background-color:rgba(79, 209, 196, 0.25);
  cursor: not-allowed;
}

.no-auth-input>ul{
  margin-top: -0.25rem;
  list-style: none;
  margin-left: -2rem;
}
.no-auth-input>ul>li{
  font-size: 0.75rem;
}
