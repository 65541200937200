.home {
  background-color: #F5F5F5;
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow-y: hidden;
}

.home-left {
  width: 16%;
}

.home-right {
  /* background-color: brown; */
  width: 84%;
  /* height: 100; */
}


/* ALL SCREEN BREAKPOINTS */

/* For Mobile Devices – 320px — 480px.
iPads and Tablets – 481px — 768px.
Laptops and small screen – 769px — 1024px.
Large screens and Desktops – 1025px — 1200px.
TV and Extra Large Screens – 1201px and more. */

/* Media Query for Mobile */

@media screen and (min-width: 320px) and (max-width: 480px) {
.home-right{
  width: 100%;
  
}
.home-left{
  width: 0%;
}
}

/* Media Query for iPads and Tablets  */

@media screen and (min-width: 481px) and (max-width: 768px) {
}

/* Media Query for Laptops and small screen .  */

@media screen and (min-width: 769px) and (max-width: 1024px) {
}

/* Media Query for TV and Extra Large Screens */

@media screen and (min-width: 1201px) {
}
