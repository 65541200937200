@import url("https://fonts.googleapis.com/css2?family=Alkatra&family=Golos+Text:wght@400;500&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Oswald&family=Roboto+Mono:ital@0;1&family=Sedan+SC&family=Shantell+Sans:ital,wght@0,400;1,300&display=swap");
html,
body {
  margin: 0;
  padding: 0;
}
.no-auth-header {
  background-color: #4fd1c5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 0rem 14rem 0rem;
}

.no-auth-header > div,
.no-auth-download-button {
  /* margin-top: -5rem; */
}

.no-auth-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 100px;
}

.no-auth-logo > span {
  color: black;
  font-weight: 700;
  font-size: 32px;
  /* font-family: ; */
  margin: 20px;
  font-family: "Nunito", sans-serif;
}

.no-auth-logo > img {
  height: 5rem;
  width: 5rem;
  object-fit: contain;
  /* padding: 30px;  */
}

.buttons {
  display: flex;
  margin: 0.5rem;
gap: 0.5rem;
}

.buttons > button {
  border: none;
  background-color: transparent;
  font-size: 1.3rem;
  font-weight: 800;
  padding: 0.25rem 0.75rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: "Nunito", sans-serif;
}

.buttons > button:hover {
  background-color: rgba(186, 210, 210, 0.61);
  border-radius: 12px;
  /* padding: 8px 14px; */
}

.buttons > button > img {
  height: 40px;
  width: 40px;
  object-fit: contain;
  padding: 5px;
}

.no-auth-download-button {
  border: none;
  background-color: #39475d;
  font-size: 1.1rem;
  font-weight: 700;
  padding:0.75rem 1.25rem;
  display: flex;
  align-items: center;
  color: white;
  border-radius: 20px;
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: pointer;
  font-family: "Nunito", sans-serif;
}
.active-tab{
  background-color: rgba(186, 210, 210, 0.61) !important;
  border-radius: 12px !important;
  border: 1px solid rgba(0, 0, 0,0.15)!important;
  cursor:not-allowed !important; 
}

/* ALL SCREEN BREAKPOINTS */

/* For Mobile Devices – 320px — 480px.
  iPads and Tablets – 481px — 768px.
  Laptops and small screen – 769px — 1024px.
  Large screens and Desktops – 1025px — 1200px.
  TV and Extra Large Screens – 1201px and more. */

/* Media Query for Mobile */

@media screen and (min-width: 320px) and (max-width: 480px) {
  .no-auth-header {
    flex-direction: column;
    gap: 0;
    /* height: 40vh; */
  }
  .no-auth-logo {
    margin-left: 0;
  }
  .buttons > button {
    padding: 3px 8px;
  }
  
  .no-auth-download-button {
    margin-top: 0px;
  }
  .no-auth-welcome-section {
    margin: 20px auto;
    width: 80%;
  }
  .no-auth-download-button {
    margin: 0px 5px 20px 5px;
  }
  .no-auth-container {
    padding-bottom: 20px;
  }
}

/* Media Query for iPads and Tablets  */

@media screen and (min-width: 481px) and (max-width: 768px) {
  .no-auth-header {
    flex-direction: column;
    gap: 0;
    /* height: 33vh; */
  }
  .no-auth-logo {
    margin-left: 0;
  }
  .buttons > button {
    padding: 5px 8px;
  }
  
  .no-auth-download-button {
    margin-top: 0px;
  }
  .no-auth-welcome-section {
    margin: 20px auto;
    width: 80%;
  }
  .no-auth-download-button {
    margin: 0px 5px 20px 5px;
  }
  .no-auth-container {
    padding-bottom: 20px;
  }
}

/* Media Query for Laptops and small screen .  */

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .no-auth-header {
    flex-direction: column;
    gap: 0;
    /* height: 33vh; */
  }
  .no-auth-logo {
    margin-left: 0;
  }
  .buttons > button {
    padding: 5px 8px;
  }
 
  .no-auth-download-button {
    margin-top: 0px;
  }
  .no-auth-welcome-section {
    margin: 20px auto;
    width: 60%;
  }
  .no-auth-download-button {
    margin: 0px 5px 20px 5px;
  }
  .no-auth-container {
    padding-bottom: 20px;
  }
}

/* Media Query for TV and Extra Large Screens */

@media screen and (min-width: 1201px) {
}

.no-auth-welcome-section {
  border: 1px solid black;
  width: 30%;
  margin: auto;
  margin-top: -2rem;
  background-color: white;
  border-radius: 10px;
  padding: 6px 30px;
}

.no-auth-welcome-section > p > span {
  color: #4fd1c5;
  font-weight: 700;
  font-size: 30.72px;
  font-family: "Nunito", sans-serif;
}

.no-auth-welcome-section > p {
  font-size: 16.896px;
  font-family: "Nunito", sans-serif;
}

.no-auth-input {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  gap: 8px;
  position: relative;
  font-family: "Nunito", sans-serif;
}

.no-auth-input > img {
  width: 20px;
  height: 20px;
  object-fit: cover;
  position: absolute;
  top: 44px;
  right: 10px;
  display: none;
}
.no-auth-input > input {
  padding: 14px 10px;
  border-radius: 5px;
  font-size: 12px;
  border: 2px solid rgba(128, 128, 128, 0.838);
  font-family: "Nunito", sans-serif;
}

.no-auth-input > label {
  font-size: 17px;
  font-weight: 500;
  font-family: "Nunito", sans-serif;
}

.submit-no-auth {
  margin-top: 15px;
  background-color: #4fd1c5;
  padding: 14px 80px;
  width: 100%;
  border-radius: 7px;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 700;
  transition: background-color 0.5s ease-in;
  font-family: "Nunito", sans-serif;
}

.submit-no-auth:hover {
  background-color: #4fd1c4d1;
  color: rgba(0, 0, 0, 0.5);
}

.no-auth-footer {
  margin: 10px;
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  font-family: "Nunito", sans-serif;
}

.forgot-link {
  color: #10468d;
  font-weight: 700;
  text-decoration: none;
  font-size: 17px;
  font-family: "Nunito", sans-serif;
}

.log-in-link {
  color: #4fd1c5;
  font-weight: 700;
  text-decoration: none;
  font-size: 17px;
  font-family: "Nunito", sans-serif;
}

.leaf-footer {
  width: 100%;
}

.leaf-footer > img {
  height: 15px;
  width: 15px;
  object-fit: contain;
}

.leaf-footer > span {
  color: rgba(128, 128, 128, 0.568);
  font-size: 16px;
  font-weight: 600;
  font-family: "Nunito", sans-serif;
}

#no-auth-error-message {
  color: red;
  font-size: small;
  width: 100%;
  text-align: center;
  font-family: "Nunito", sans-serif;
}
