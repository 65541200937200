.home-right-navbar {
  background-color: #4fd1c5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.75rem 0;
  border-radius: 10px 10px 0 0;
}
.home-right-navbar > :nth-child(1) {
  width: 75%;
}
.mobile-nav {
  display: none;
}
.nav-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 1rem;
}


.navbar-search {
  width: 100%;
  position: relative;
  margin-left: 1rem;
 }

.navbar-search > input {
  width: 100%;
  padding: 7px;
  border-radius: 1rem;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.239) 0px 3px 8px;

  padding-left: 2.5rem;
}
.navbar-search>input:focus{
  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 8px;
 outline: none;
}

.navbar-search > button {
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.nav-right{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.nav-right > img {
  height: 45px;
  width: 45px;
  object-fit: contain;
}

.nav-right > button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1rem;
  font-weight: 570;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 1.5rem;
  transition: box-shadow 0.2s ease-in;
}
.nav-right > button:hover {
  box-shadow: rgba(63, 62, 62, 0.15) 0px 8px 16px;

}
.toggle-search {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.toggle-search > i {
  display: none;
  width: 1rem;
  height: 2rem;
  padding: 0rem 0.5rem 0rem 1rem;
}

.mobile-nav-logo {
  margin-left: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.mobile-nav-logo > img {
  width: 2rem;
  height: 2rem;
  object-fit: contain;
  max-width: 5rem;
  max-height: 5rem;
}
.mobile-nav-logo > p {
  color: rgba(0, 0, 0, 0.61);
  font-size: 1.5rem;
  max-width: 100%;
  font-weight: 600;
}
.mobile-nav-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 1rem;
}
.mobile-nav-right > i {
  display: flex;
  align-items: center;
  color: grey;
  font-size: 2rem;
}
.custom-toast {
  background-color: purple !important;
  color: white !important;
}
.mobile-nav-right > button {
  background-color: transparent;
  border: none;
  color: grey;
  font-size: 1.2rem;
  font-weight: 570;
  cursor: pointer;
}
/* ALL SCREEN BREAKPOINTS */

/* For Mobile Devices – 320px — 480px.
iPads and Tablets – 481px — 768px.
Laptops and small screen – 769px — 1024px.
Large screens and Desktops – 1025px — 1200px.
TV and Extra Large Screens – 1201px and more. */

/* Media Query for Mobile */

@media screen and (min-width: 320px) and (max-width: 480px) {
  .nav-right {
    display: none;
  }
  .toggle-search > i {
    display: block;
  }
  .mobile-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

/* Media Query for iPads and Tablets  */

@media screen and (min-width: 481px) and (max-width: 768px) {
  .home-right-navbar{
    display: flex;
    flex-direction: column;
   
  }

  
  .nav-right {
    display: none;
  }
  .toggle-search > i {
    display: block;
  }
  .mobile-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

/* Media Query for Laptops and small screen .  */

@media screen and (min-width: 769px) and (max-width: 1024px) {
}

/* Media Query for TV and Extra Large Screens */

@media screen and (min-width: 1201px) {
}
