@import url("https://fonts.googleapis.com/css2?family=Alkatra&family=Golos+Text:wght@400;500&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Oswald&family=Roboto+Mono:ital@0;1&family=Sedan+SC&family=Shantell+Sans:ital,wght@0,400;1,300&display=swap");


.no-auth-welcome-section {
  border: 1px solid black;
  width: 30%;
  margin: auto;
  margin-top: -11rem;
  background-color: white;
  border-radius: 10px;
  padding: 6px 30px;
}

.no-auth-welcome-section > p > span {
  color: #4fd1c5;
  font-weight: 700;
  font-size: 30.72px;
  font-family: 'Nunito', sans-serif;
}

.no-auth-welcome-section > p {
  font-size: 16.896px;
  font-family: 'Nunito', sans-serif;
}

.no-auth-input {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  gap: 8px;
  position: relative;
  font-family: 'Nunito', sans-serif;
}

.no-auth-input > img {
  width: 20px;
  height: 20px;
  object-fit: cover;
  position: absolute;
  top: 44px;
  right: 10px;
  display: none;
}
.no-auth-input > input {
  padding: 14px 10px;
  border-radius: 5px;
  font-size: 12px;
  border: 2px solid rgba(128, 128, 128, 0.838);
  font-family: 'Nunito', sans-serif;
}

.no-auth-input > label {
  font-size: 17px;
  font-weight: 500;
  font-family: 'Nunito', sans-serif;
}

.submit-login {
  margin-top: 15px;
  background-color: #4fd1c5;
  padding: 14px 80px;
  width: 100%;
  border-radius: 7px;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 700;
  transition: background-color 0.5s ease-in;
  font-family: 'Nunito', sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.submit-login:hover {
  background-color: #4fd1c4d1;
  color: rgba(0, 0, 0, 0.5);
}

.no-auth-footer {
  margin: 10px;
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
}

.forgot-link {
  color: #10468d;
  font-weight: 700;
  text-decoration: none;
  font-size: 17px;
  font-family: 'Nunito', sans-serif;
  line-height: 2.5rem;
}

.log-in-link {
  color: #4fd1c5;
  font-weight: 700;
  text-decoration: none;
  font-size: 17px;
  font-family: 'Nunito', sans-serif;
}

.leaf-footer {
  width: 100%;
}

.leaf-footer > img {
  height: 15px;
  width: 15px;
  object-fit: contain;
}

.leaf-footer > span {
  color: rgba(128, 128, 128, 0.568);
  font-size: 16px;
  font-weight: 600;
  font-family: 'Nunito', sans-serif;
}

.no-auth-error{
  color: red;
  font-size: small;
  width: 100%;
  text-align: center;
  font-family: 'Nunito', sans-serif;
}

.no-auth-success{
  color: #10468D;
  font-size: 16px;
  font-weight: 800;
  text-align: center;
  width: 100%;
  font-family: 'Nunito', sans-serif;
}
