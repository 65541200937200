.main-content-container {
  padding: 2rem;
  margin-top: -1rem;
  background-color: rgb(255, 255, 255);
  border-radius: 0.5rem;
  border: 1px solid black;
  height:80%;
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.main-content-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.main-content-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.note-card-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.main-content-container > p {
  font-size: 1.25rem;
}
/* In your CSS file (e.g., App.css) */
.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
}
.main-content-loader{
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: -0.5rem;
}

.message {
  font-size: 1.5rem;
  font-weight: bold;
  color: #555;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


/* ALL SCREEN BREAKPOINTS */

/* For Mobile Devices – 320px — 480px.
iPads and Tablets – 481px — 768px.
Laptops and small screen – 769px — 1024px.
Large screens and Desktops – 1025px — 1200px.
TV and Extra Large Screens – 1201px and more. */

/* Media Query for Mobile */

@media screen and (min-width: 320px) and (max-width: 480px) {
.sidebar-web{
  display: none;
}
}

/* Media Query for iPads and Tablets  */

@media screen and (min-width: 481px) and (max-width: 768px) {
}

/* Media Query for Laptops and small screen .  */

@media screen and (min-width: 769px) and (max-width: 1024px) {
}

/* Media Query for TV and Extra Large Screens */

@media screen and (min-width: 1201px) {
}
