.sidebar {
  display: flex;
  flex-direction: column;
  padding: 0 0;
  position: fixed;
  width: 16%;
}
.sidebar-web{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.sidebar-web>hr{
 border: 1px solid rgba(0, 0, 0, 0.1);
 width: 60%;
}
.sidebar-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
 }
.sidebar-logo > img {
  width: 2.8rem;
  height: 2.8rem;
  object-fit: contain;
  max-width: 5rem;
  max-height: 5rem;
}
.sidebar-logo > p {
  color: rgba(0, 0, 0, 0.61);
  font-weight: bold ;
  font-size:1.6rem;
  max-width: 100%;
}

.sidebar-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24rem;
  margin-top: 2rem;
}
.sidebar-tabs {
  display: flex;
  flex-direction: column;
  width: 85%;
  justify-content: center;
  align-items: center;
  gap: 1rem;

}
.sidebar-tabs > div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  border: 1px solid #000000af;
  padding: 0.25rem 0.2rem ;
  border-radius: 0.5rem;
  gap: 0.3rem;
  cursor: pointer;
  background-color: white;
  transition: background-color 0.2s ease-in;
  transition: transform 0.2s ease-in;
}

.sidebar-tabs > div:hover, .active-sidebar-tab {
  background-color: rgba(224, 224, 224, 0.858) !important;
  transform: scale(1.05);
}
.sidebar-tabs > div > i {
  margin-left: 1rem;
  background-color: rgb(8, 166, 166);
  color: white;
  font-size: 0.75rem;
  padding: 0.25rem;
  border-radius: 100%;
}
.sidebar-tabs > div > span {
  color: #2d3748;
  font-weight: bold;
  font-size: 0.75rem;
}

.sidebar-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  /* top: 200%; */
}
.sidebar-footer > img {
  width: 1rem;
  height: 1rem;
}
.sidebar-footer > p {
  color: #2d3748;
  font-weight: 500;
  font-size: 0.75rem;
}
#sidebar-mobile {
  display: none;
}

/* ALL SCREEN BREAKPOINTS */

/* For Mobile Devices – 320px — 480px.
iPads and Tablets – 481px — 768px.
Laptops and small screen – 769px — 1024px.
Large screens and Desktops – 1025px — 1200px.
TV and Extra Large Screens – 1201px and more. */

/* Media Query for Mobile */

@media screen and (min-width: 320px) and (max-width: 480px) {
  #sidebar-mobile {
    display: block;
    position: fixed;
    background-color: #e3e3e3;
    top: 29%;
    left: 0;
    z-index: 100;
    width: 50vw;
    height: 100vh;
    transition: width 0.5s ease-in;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .sidebar {
    position: relative;
  }

  .sidebar-content {
    width: 0;
    max-width: 80%;
    padding: 0;
    animation: showContent 0.5s forwards;
    margin: 2rem auto;
    gap: 1rem;
  }

  .sidebar-tabs > div > span {
    font-size: 1rem;
    width: 100%;
  }
  .hide {
    animation: hideContent 0.5s forwards;
    overflow-x: hidden;
  }
  @keyframes hideContent {
    0% {
      width: 100%;
      opacity: 1;
    }
    50% {
      width: 50%;
      opacity: 0.5;
    }
    100% {
      width: 0%;
      opacity: 0;
      display: none;
    }
  }
  @keyframes showContent {
    0% {
      width: 0%;
      opacity: 0;
      display: none;
    }
    50% {
      width: 50%;
      opacity: 0.5;
    }
    100% {
      width: 100%;
      opacity: 1;
      display: flex;
    }
  }
}

/* Media Query for iPads and Tablets  */

@media screen and (min-width: 481px) and (max-width: 768px) {
  #sidebar-mobile {
    display: block;
    position: fixed;
    background-color: #e3e3e3;
    top: 29%;
    left: 0;
    z-index: 100;
    width: 50vw;
    height: 100vh;
    transition: width 0.5s ease-in;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .sidebar-web{
    display: none;
  }

  .sidebar {
    position: relative;
  }

  .sidebar-content {
    width: 0;
    max-width: 80%;
    padding: 0;
    animation: showContent 0.5s forwards;
    margin: 2rem auto;
    gap: 1rem;
  }

  .sidebar-tabs > div > span {
    font-size: 1rem;
    width: 100%;
  }
  .hide {
    animation: hideContent 0.5s forwards;
    overflow-x: hidden;
  }
  @keyframes hideContent {
    0% {
      width: 100%;
      opacity: 1;
    }
    50% {
      width: 50%;
      opacity: 0.5;
    }
    100% {
      width: 0%;
      opacity: 0;
      display: none;
    }
  }
  @keyframes showContent {
    0% {
      width: 0%;
      opacity: 0;
      display: none;
    }
    50% {
      width: 50%;
      opacity: 0.5;
    }
    100% {
      width: 100%;
      opacity: 1;
      display: flex;
    }
  }
}

/* Media Query for Laptops and small screen .  */

@media screen and (min-width: 769px) and (max-width: 1024px) {
}

/* Media Query for TV and Extra Large Screens */

@media screen and (min-width: 1201px) {
}
